import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import styles from './artical-preview.css'
import bg from '../../images/with-yoyo/with-yoyo-bg1.jpg'


// let dateli = this.props.article.publishDate.split(' ');
// let monthNum = {
//   Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6,
//   Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12  
// };
// let datestr = dateli[2] + '-' + monthNum[dateli[0].match(/^([a-z]{3})/i)[1]].toString().padStart(2, '0') +  '-' + dateli[1].substr(0, dateli[1].length-3).toString().padStart(2, '0');

// this.state = {
//   date: datestr
// };

export default ({ article }) => (
    <div className="ArticalPreviewGroup">
      <a href={`/blogs/${article.slug}`} target="_blank">
          <div className="preview-image"><img src={article.heroImage.fluid.src} alt="" /></div>
          <h3 className="preview-title">{article.title}</h3>
          <div className="preview-description"
              dangerouslySetInnerHTML={{
                __html: article.description.childMarkdownRemark.html,
              }}
            />

          <div className="preview-info-group">
            <label className="preview-date">🗓️ {article.publishDate}</label>
            <div className="articles-preview-tags">
            {article.tags &&
              article.tags.map(tag => (
                <label className="preview-tag" key={tag}>
                  🔖 {tag}
                </label>
            ))}
            </div>
          </div>

      </a>
    </div>
)